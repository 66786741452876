import { Brand } from './typings'

const athleta: Brand = {
  clientInfo: {
    displayName: 'Athleta',
    accountNumberLengths: [16],
    footerDisplay: 'Athleta.com',
    footerLink: 'https://www.Athleta.com/',
    applicationUrl: '',
    dsecApigeeClientId: {
      development: 'FbpSG1OPK8e3b6fqKDmXdJc7LDMvCpOo',
      production: '2S3HA7dMXhpPR2Xqh2sgCnYX3X25vVfG'
    },
    persistApigeeClientId: { development: '', production: '' },
    setpayApigeeClientId: {
      development: '',
      production: ''
    },
    metaTagCardTitle: {
      en: 'Credit Card',
      es: 'Tarjeta de Crédito'
    },
    metaTagCardDescription: {
      en: `Log into your Athleta credit card account online to pay your bills, check your FICO score, sign up for paperless billing, and manage your account preferences`,
      es: `Ingrese a su cuenta de la Tarjeta de Crédito Athleta en línea para pagar sus facturas, revisar su puntuación FICO, inscribirse en facturas digitales y manejar su cuenta.`
    },
    languages: ['en'],
    vanityUrl: 'Athleta.com',
    hostname: 'Athleta.syf.com',
    linkingRedirect: '',
    ivaKey: '',
    hasMss: true,
    hasCpl: false,
    promoUrl: 'https://athleta.barclaysus.com/',
    hasCplOldErrorCode: false,
    isMultiAccount: false,
    footerContent: {
      en: 'Your Athleta Rewards Visa® or Athleta Rewards Credit Card is issued by Synchrony Bank. The Synchrony Bank Privacy Policy governs the use of the Athleta Rewards Visa® or Athleta Rewards Credit Card. The use of this site is governed by the use of the Synchrony Bank Online Privacy Policy, which is different from the privacy policy of Athleta.',
      es: ''
    },
    phoneNumbers: {
      loan: '',
      plcc: '1-855-327-4359',
      dualcard: '1-855-327-4358',
      fraud: '1-877-350-4039',
      customerService: '1-855-622-2675'
    },
    iOSAppId: '',
    androidApp: {
      id: '',
      author: '',
      title: '',
      image: ''
    },
    crossBorder: false,
    cplHeroBannerDisplayName: '',
    termsOfService: {
      en: '/essimages/cs/groups/ess_webasset/dualcard/@athleta/en/html/ess003105.html',
      es: ''
    },
    onlinePrivacy: {
      en: '/essimages/cs/groups/ess_webasset/generic/@athleta/en/html/ess013958.html',
      es: ''
    },
    privacyPolicy: {
      en: '/essimages/cs/groups/ess_webasset/generic/@athleta/en/html/ess014248.html',
      es: ''
    },
    websiteUsage: {
      en: '/essimages/cs/groups/ess_webasset/generic/@athleta/en/html/ess014254.html',
      es: ''
    },
    voice: {
      cardName: '',
      google: {
        termsAndConditionsLink: ''
      },
      alexa: {
        termsAndConditionsLink: ''
      }
    }
  },
  palette: {
    field: {
      icon: '#76777A',
      focus: '#34657F',
      label: ''
    },
    progressStepBar: {
      fill: '#983E97',
      label: '#983E97'
    },
    progressBar: {
      complete: '',
      error: '#c63527',
      inProgress: '#0072ad',
      background: '#e9eaeb'
    },
    button: {
      primary: {
        text: '#FFFFFF',
        hover: '#3d3d3d'
      },
      secondary: {
        hover: '#d9d9d9'
      },
      background: '#76777A'
    },
    checkbox: {
      background: '#983e97',
      font: '#FFFFFF'
    },
    switch: { background: '' },
    heroBackground: '#FFFFFF',
    bodyBackground: '#FFFFFF',
    iconBackground: '#983E97',
    headerBackground: '#FFFFFF',
    modalBackground: '#FFFFFF',
    formBackground: '',
    headerDivider: '#3C5E72',
    footerDivider: '#FFF',
    contrastingText: '#FFF',
    bodyText: '#333',
    languageSelector: '#983e97',
    focus: '',
    link: '#76777A',
    footer: {
      background: '#F2F2F2',
      text: '#333',
      link: '#333'
    }
  },
  images: {
    logo: {
      en: '/assets/athleta/logo.png',
      es: '/assets/athleta/logo.png'
    },
    hero: '',
    heroBgDesktop: '',
    heroBgMobile: '',
    mainPromo: {
      en: '/assets/athleta/promo.png',
      es: '/assets/athleta/promo.png'
    },
    voiceCplLogo: ''
  }
}

export default athleta
